// بسم الله الرحمن الرحيم
// ما شاء الله
// بسم الله الرحمن الرحيم
// بسم الله الرحمن الرحيم
// ما شاء الله
// بسم الله الرحمن الرحيم
// ما شاء الله 
// بسم الله الرحمن الرحيم
// ما شاء الله
// ما شاء الله




document.addEventListener('DOMContentLoaded', () => {
  const searchInput = document.getElementById('search-input');
  const searchButton = document.getElementById('search-button');
  const searchResults = document.getElementById('search-results');
  const searchForm = document.querySelector('form');

  searchForm.addEventListener('submit', (e) => {
    e.preventDefault();

    // Simulate search results
    const searchTerm = searchInput.value;
    const results = [
      { title: 'Result 1', description: 'This is the first result' },
      { title: 'Result 2', description: 'This is the second result' },
      { title: 'Result 3', description: 'This is the third result' },
    ];

    // Clear previous search results
    searchResults.innerHTML = '';

    // Add a loading animation
    const loadingAnimation = document.createElement('div');
    loadingAnimation.classList.add('loading-animation');
    searchResults.appendChild(loadingAnimation);

    // Simulate a delay before showing the search results
    setTimeout(() => {
      // Remove the loading animation
      searchResults.removeChild(loadingAnimation);

      // Add the search results
      results.forEach((result) => {
        const resultElement = document.createElement('div');
        resultElement.classList.add('search-result');
        resultElement.innerHTML = `
          <h2>${result.title}</h2>
          <p>${result.description}</p>
        `;
        searchResults.appendChild(resultElement);
      });
    }, 2000);
  });
});


